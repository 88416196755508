import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(
    private http: HttpClient,
  ) { }

  public createAdd(body) {
    return this.http.post<any>(`${environment.uri}/lastproperties`, body)
      .pipe(
        map( response => response )
      );
  }

  public createPost(body) {
    return this.http.post<any>(`${environment.uri}/createpost`, body)
      .pipe(
        map( response => response )
      );
  }

  public searchAllPost(body) {
    return this.http.post<any>(`${environment.uri}/searchAllPost`, body)
      .pipe(
        map( response => response )
      );
  }

  public getAllRegions() {
    return this.http.get<any>(`${environment.uri}/allregions`)
      .pipe(
        map( response => response )
      );
  }

  public getDistrictsByRegion(idRegion) {
    return this.http.get<any>(`${environment.uri}/getDistrictByRegions/${idRegion}`)
      .pipe(
        map( response => response )
      );
  }

  public getAllCurrency() {
    return this.http.get<any>(`${environment.uri}/allcurrency`)
      .pipe(
        map( res => res.data )
      );
  }

  public findPost(code: string) {
    return this.http.get<any>(`${environment.uri}/findPost/${code}`)
      .pipe(
        map( res => res.data )
      );
  }

  public findPostByUser(body) {
    return this.http.post<any>(`${environment.uri}/searchpostbyuser`, body)
      .pipe(
        map( response => response )
      );
  }

  public disabledPost(body) {
    return this.http.put<any>(`${environment.uri}/disabledPost`, body)
      .pipe(
        map( response => response )
      );
  }

  public editPost(body) {
    return this.http.post<any>(`${environment.uri}/editPost`, body)
      .pipe(
        map( response => response )
      );
  }

}
